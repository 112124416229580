.shipping-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .shipping-details {
    flex: 1;
    margin-right: 20px;
  }
  
  .order-summary {
    flex: 1;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  form label {
    margin-bottom: 5px;
  }
  
  form input {
    padding: 8px;
    box-sizing: border-box;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
  }
  
  .confirm-button {
    padding: 10px 20px;
    background-color: darkblue;
    color: white;
    border: 2px solid darkblue;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: blue;
    border-color: blue;
  }
  
  .cancel-button {
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: 2px solid red;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-button:hover {
    background-color: pink;
    border-color: pink;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border: 1px solid lightgray;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
 
          /* Mobile Styles */
@media (max-width: 768px) {
    .shipping-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px;
      }
      
      .shipping-details {
        flex: 1;
        margin-right: 20px;
      } 
}