.banner_container {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 20px; /* Adjust based on the navbar height */
    display: flex;
    justify-content: center; /* Center the banner */
    
}

.banner {
    width: 90%;
    max-height: 200px;
    
}

.banner-image {
    width: 100%;
    height: 200px; /* Set the height */
    object-fit: fill; /* Ensure the image covers the entire area */
    border-radius: 5px;
    background-color: rgb(11, 51, 231);
}

.slick-dots {
    bottom: 10px;
}

.banner-slide {
    position: relative;
}

.slick-dots li button:before {
    font-size: 12px;
    color: black;
}

.slick-dots li.slick-active button:before {
    color: black;
}

.banner-text {
    position: absolute;
    top:  30px;
    left: 25%;
    transform: translateX(-50%);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .banner_container {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-top: 10px; /* Adjust based on the navbar height */
        display: flex;
        justify-content: center; /* Center the banner */
        align-items: center;
        
    }
    
    .banner {
        width: 95%;
        max-height: 95px;
        
    }
    .banner-image {
        width: 100%;
        height: 100px; /* Set the height */
        object-fit:fill; /* Ensure the image covers the entire area */
        border-radius: 20px;
        background-color: rgb(15, 43, 183);
    }

    .banner-text {
        position: absolute;
        top:  10px;
        left: 35%;
        transform: translateX(-50%);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
    }
}
