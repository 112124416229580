.cart-details {
    padding: 20px;
    margin: 0 10%;
  }
  
  .cart-details h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .cart-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .cart-table th,
  .cart-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .cart-table th {
    background-color: #f2f2f2;
  }
  
  .remove-button {
    padding: 5px 10px;
    background-color: rgb(146, 11, 11);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
  }
  
  .remove-button:hover {
    background-color: rgb(60, 10, 18);
  }
  
  .proceed-button, .back-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: darkblue;
    color: white;
    text-decoration: none;
    border: 2px solid darkblue;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .proceed-button:hover, .back-button:hover {
    background-color: blue;
    border-color: blue;
  }
  
  .back-button {
    background-color: darkgray;
    border: 2px solid darkgray;
    margin-right: 10px;
  }
  
  .back-button:hover {
    background-color: gray;
    border-color: gray;
  }
  
  .cart-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .cart-table th.action-column,
  .cart-table td.action-column {
    width: 120px; 
  }

        /* Mobile Styles */
@media (max-width: 768px) {
    .cart-details {
        padding: 10px;
        margin: 0 5%;
      }
    .cart-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
    .cart-buttons {
        display: flex;
        justify-content:center;
        gap: 10px;
        margin-top: 20px;
    }  
}
  