/* src/components/ProductListing/ProductListing.css */
.product-listing {
    display: grid;
    margin: 10px 50px;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .product-card {
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .product-card img {
    width: 100px;
    height: 100px;
  }

  /* Mobile Styles */
@media (max-width: 768px) {
    .product-listing {
        display: grid;
        margin: 10px 50px;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        padding: 20px;
      }
}
  