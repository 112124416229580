/* src/App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: rgb(241, 241, 238);
}
.app_body{
  min-height: 420px;
  
}
