.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: rgb(22, 15, 105);
    color: white;
    padding: 0 50px;
}

.navbar-logo {
    padding-left: 50px;
}

.navbar-menu {
    display: flex;
    gap: 30px;
}

.navbar-item {
    text-decoration: none;
    background-color: rgba(247, 247, 247, 0.95);
    color: rgb(22, 15, 105);
    padding: 5px 15px;
    border-radius: 5px;
    border: 2px solid rgb(22, 15, 105);
    transition: background-color 0.3s, color 0.3s;
    font-weight: 700;
}

.navbar-item:hover {
    background-color: rgb(150, 150, 154);
    color: white;
}

.navbar-actions {
    display: flex;
    align-items: center;
    gap: 50px;
}

.navbar-cart {
    width: 50px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    background-color:rgba(247, 247, 247, 0.95);
    padding: 5px;
    border-radius: 5px;
    border: 2px solid rgb(22, 15, 105);
}
.navbar-cart:hover {
    background-color: rgb(150, 150, 154);
}

.cart-icon {
    width: 24px;
    height: 24px;
    margin-top: 10px;
    margin-left: 5px;
}

.cart-count {
    position: absolute;
    top: -5px;
    right: -30px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
}

.navbar-language {
    padding: 5px;
}

/* Mobile Styles */
@media (max-width: 768px) {

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        background-color: rgb(22, 15, 105);
        color: white;
        padding: 0 23px;
    }
    .navbar-logo {
        display: none;
    }

    .navbar-item {
        text-decoration: none;
        background-color: rgba(247, 247, 247, 0.95);
        color: rgb(22, 15, 105);
        padding: 2px 10px;
        border-radius: 5px;
        border: 1px solid rgb(22, 15, 105);
        transition: background-color 0.3s, color 0.3s;
        height: 35px;
        font-size: 12px;
        line-height: 1.2;
    }

    .navbar-menu {
        display: flex;
        gap: 10px;
        margin-right: 10px;
    }
    .navbar-actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .navbar-cart {
        width: 50px;
        height: 35px;
        position: relative;
        display: flex;
        align-items: center;
        background-color:rgba(247, 247, 247, 0.95);
        padding: 5px;
        border-radius: 5px;
        border: 2px solid rgb(22, 15, 105);
    }

}