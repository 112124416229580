/* src/components/Footer/Footer.css */
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
    background-color: rgb(189, 188, 187);
    color: white;
    justify-content: center;
  }
  
  .footer-links {
    display: flex;
    gap: 30px;
  }
  
  .footer-item {
    text-decoration: none;
    color: white;
  }
  
  .footer-item:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    margin-top: 5px;
  }
  