    /* src/components/ProductDetails/ProductDetails.css */
    .product-details {
        display: flex;
        justify-content:space-between;
        margin-left: 10%;
        margin-right: 20%;
        gap: 40px;
        padding: 20px;
        align-items: center;
    }
    
    .image-gallery {
        display: flex;
        flex-direction: column;
        gap: 1px;
        
    }
    
    .main-image {
        width: 410px;
        height: 410px;
        border-radius: 8px;
        margin-bottom: 2px;
    }
    
    .thumbnail-images img {
        width: 100px;
        height: 100px;
        cursor: pointer;
        margin: 1px;
        transition: border 0.3s;
        border-radius: 8px;
    }
    
    .thumbnail-images img:hover {
        border: 2px solid #272626;
    }
    
    .product-info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: left;
    }
    
    .product-info h2 {
        font-size: 1.5rem;
    }
    
    .product-info p {
        font-size: 1rem;
    }
    
    .product-info label {
        font-size: 1rem;
        margin-right: 10px;
    }
    
    .product-info select {
        padding: 5px;
        font-size: 1rem;
    }
    
    .product-info button {
        padding: 10px 20px;
        font-size: 1rem;
        background-color: rgb(11, 22, 115);
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }
    
    .product-info button:hover {
        background-color: rgb(19, 2, 91);
    }

      /* Mobile Styles */
@media (max-width: 768px) {
    .product-details {
        display: flex;
        justify-content:space-between;
        margin-left: 10%;
        margin-right: 20%;
        gap: 10px;
        padding: 20px;
        align-items: center;
        flex-direction: column;
    }
    .main-image {
        width: 210px;
        height: 210px;
        border-radius: 8px;
        margin-bottom: 2px;
    }
    
    .thumbnail-images img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        margin: 1px;
        transition: border 0.3s;
        border-radius: 8px;
    }
}
    